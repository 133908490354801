body {
  margin: 0;
  padding: 0;
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-weight: 300 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  color: #202020;
}

body > h1,h2,h3,h4 {
  font-weight: 300 !important;
}

a, p {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-radio-checked .ant-radio-inner, .ant-radio:hover .ant-radio-inner {
  border-color: #1babbc !important;
}

.ant-radio-inner:after {
  background-color: #1babbc !important;
}

.ant-input:hover {
  border-color: #1babbc !important;
}

.ant-input-number:focus, .ant-input-number:hover {
  border-color: #1babbc !important;
  box-shadow: 0 0 0 2px #40a9ff33 !important;
}
